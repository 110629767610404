import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import BlogFeed from "../../components/blog-feed";
import JoinTheTeamForm from "../../components/join-the-team-form";

const SalesAndDesignConsultant = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Sales and Design Consultant"
        description="The perfect person for this role will have both UI/UX and Sales experience, and be able to read between the lines when a client needs those efforts (Because surprise! clients don’t always SAY that’s what they need.)."
      />
      <section className="pt-6 mb-3">
        <div className="container">
          <h1>Sales and Design Consultant</h1>
          <StaticImage
            src="../../images/jobs/729_Sales-and-Design-Consultant-01-1080x675.png"
            alt="Dream Job"
          />
          <p className="pt-6">
            The perfect person for this role will have both UI/UX and Sales
            experience, and be able to read between the lines when a client
            needs those efforts (Because surprise! clients don’t always SAY
            that’s what they need.).
          </p>
          <h2>Qualifications</h2>
          <ul>
            <li>Bachelor’s (Preferred)</li>
            <li>Sales Experience: 2+ years (Required)</li>
          </ul>
          <h2 className="pt-6">Key responsibilities</h2>
          <ul>
            <li>
              Ability to demonstrate knowledge of UI/UX, Web, Graphic Design and
              Front End Development industries
            </li>
            <li>
              Develop design proposals for UI/UX, Web, Graphic design and front
              end development projects by way of Discovery planning, detail
              gathering sessions and documentation of relevant client needs
            </li>
            <li>
              Fully understand the Discovery process and ability to translate
              client needs into actionable backlog with defined deliverables
            </li>
            <li>Meet monthly and annual sales targets</li>
            <li>Produce proposals, quotes and design presentations</li>
            <li>
              Sales negotiations, lead management, snagging and after sales
              service
            </li>
            <li>
              Develop and foster relationships with new and existing industry
              clientele
            </li>
            <li>
              Identify sales leads, continuously update and maintain the CRM
              databases and sales pipeline
            </li>
            <li>Build brand loyalty through outstanding customer service</li>
            <li>
              Ensure the presentation of proposals and other sales documents
              adhere to brand identity standards
            </li>
            <li>
              Liaise with existing partners and foster new partner relationships
              relevant to the Creative department
            </li>
            <li>
              Attend events/exhibitions as and when required to promote the
              creative department and attract leads
            </li>
          </ul>
          <h2 className="pt-6">Key skills and requirements</h2>
          <ul>
            <li>
              Proven track record in high-end UI/UX, Web, Graphic Design and
              Front End Development Industries
            </li>
            <li>A design-related qualification</li>
            <li>
              A passion for UI/UX, Web, Graphic Design and Front End Development
              Processes
            </li>
            <li>Strong sales and negotiation skills</li>
            <li>
              Enthusiastic, ambitious, creative, sales driven and team oriented
            </li>
            <li>
              Must be proactive and highly organized with the ability to
              multitask and meet deadlines in a fast-paced environment
            </li>
            <li>Excellent presentation and interpersonal skills</li>
            <li>Knowledge of Adobe Creative Cloud Software</li>
            <li>Knowledge of Discovery Processes</li>
            <li>Excellent written and spoken English</li>
            <li>Spanish as a second language is a plus</li>
          </ul>
          <h2 className="pt-6">Benefits</h2>
          <ul>
            <li>Health insurance</li>
            <li>Dental insurance</li>
            <li>Vision insurance</li>
            <li>Paid Holidays</li>
            <li>Unlimited PTO</li>
            <li>Flex Schedule</li>
            <li>Remote Position</li>
          </ul>
          <h2 className="pt-6">Benefit Conditions</h2>
          <ul>
            <li>90 Day trial period applies</li>
          </ul>
          <h2 className="pt-6">In 300 characters or less…</h2>
        </div>
      </section>

      <section>
        <div className="container">
          <JoinTheTeamForm />
        </div>
      </section>

      <section className="pt-6">
        <BlogFeed />
      </section>
    </Layout>
  );
};

export default SalesAndDesignConsultant;
